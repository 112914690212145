<template>
  <div class="white-book-detail">
    <Main-top
      :title="name"
      class="whitebook-main-top"
      :contentDate="created_at"
    ></Main-top>
    <v-container class="mb-10 inner-container inner-container--content whitebook-inner-container">
      <v-row>
        <v-col v-bind="grid_content">
          <div class="white-book-box pa-5 pa-lg-11">
            <v-img
              v-if="isPhotoShow"
              class="mb-14 ma-auto"
              :src="photoUrl"
              :alt="photoName"
              :aspect-ratio="16 / 9"
              :max-width="ImgDetailMax"
            ></v-img>
            <div v-html="content" class="html-editor"></div>
          </div>
        </v-col>
        <v-col v-if="showForm" v-bind="grid_form" class="whitebook-Item-form">
          <Form
            formClass="pa-0 pa-lg-11 mt-1 mt-md-0"
            titleClass="text-4"
            :data="formData"
            :title="form_title"
            :form_id="form_id"
            itemFullWidth
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="!filesEmpty"
      class="mt-10 mb-10 inner-container inner-container--content"
    >
      <p class="dynamic-sub-title mb-10">{{ $t("file.download") }}</p>
      <FileBox :files="files" />
    </v-container>
  </div>
</template>
<script>
import detailMixins from "@/components/page/detail.js";
import Form from "@/components/dynamic/form/form.vue";
import EcvWaveT from "@/components/wave/ecvWaveT.vue";
export default {
  mixins: [detailMixins],
  components: {
    Form,
  },
  data: () => ({
    pageData: null,
  }),
  computed: {
    breadcrumb() {
      return [
        {
          title: this.$t("page.white-book"),
          name: "white-book-index",
        },
        {
          title: this.name,
          name: "white-book-detail",
        },
      ];
    },
    api() {
      if (this.hasPreviewLink) return this.$api.paper.admin.preview;
      return this.$api.paper.public.read;
    },
    form_title() {
      if (!this.pageData) return "";
      if (!this.pageData.meta) return "";
      return this.pageData.meta.form_title;
    },
    grid_content() {
      if (this.showForm)
        return {
          cols: 12,
          lg: 7,
          md: 12,
        };
      return {
        cols: 12,
      };
    },
    grid_form() {
      return {
        cols: 12,
        lg: 5,
        md:12,
      };
    },
    topWaveComponent() {
      return EcvWaveT;
    },
  },
  methods: {},
};
</script>